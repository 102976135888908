<template>
  <div class="l-wrapper">
    <AppHeader pageTitle="" />
    <main class="l-main">
      <div class="l-container full-h u-flex-center">
        <div class="contents u-space-btwn">
          <div v-if="status === 403">
            <h2 class="c-lead lg">403</h2>
            <p class="c-lead-lg-black l-item">アクセス権が必要です</p>
            <p class="l-block-lg">
              アクセス権を管理者にリクエストするか、アクセス権のあるアカウントに切り替えてください。
            </p>
            <router-link to="/login" class="c-text link text-link">ログインページへ</router-link>
          </div>
          <div v-else>
            <h2 class="c-lead lg">404</h2>
            <p class="c-lead-lg-black l-item">ページが見つかりません</p>
            <p class="l-block-lg">
              お探しのページは削除されているもしくはURLが変更された可能性があります。
            </p>
            <router-link to="/login" class="c-text link text-link">ログインページへ</router-link>
          </div>
          <div>
            <div class="error-icon"></div>
          </div>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
export default {
  name: "error",
  data() {
    return {
      status: 404
    };
  },
  mounted() {
    this.status = Number(this.$route.params.status);
  },
};
</script>

<style lang="scss" scoped>
.contents {
  min-width: 79rem;
  align-items: center;
}
.error-icon {
  width: 10rem;
  height: 4.4rem;
  display: block;
  background: {
    image: url("/v_resources/assets/img/icon/garbage_error.svg");
    repeat: no-repeat;
    position: center;
    size: cover;
  }
}
</style>
